import React, { useState } from 'react';
import { ethers } from 'ethers';
import './Bridge.css';

const chainOptions = [
  { name: 'Base Sepolia', dstEid: 40245, selection: 0 },
  { name: 'Optimism Sepolia', dstEid: 40232, selection: 1 },
  { name: 'Arbitrum Sepolia', dstEid: 40231, selection: 2 },
  { name: 'Linea Sepolia', dstEid: 40287, selection: 3 }
];

const stablecoinContractAddresses = {
  'Base Sepolia': '0x178FFc3586c8FBE0Dc2788E29730D16bB3b7E7Bf',
  'Optimism Sepolia': '0x178FFc3586c8FBE0Dc2788E29730D16bB3b7E7Bf',
  'Arbitrum Sepolia': '0x178FFc3586c8FBE0Dc2788E29730D16bB3b7E7Bf',
  'Linea Sepolia': '0x178FFc3586c8FBE0Dc2788E29730D16bB3b7E7Bf'
};

const stablecoinABI = [
  "function send(tuple(uint32 dstEid, bytes32 to, uint256 amountLD, uint256 minAmountLD, bytes extraOptions, bytes composeMsg, bytes oftCmd) _sendParam, tuple(uint nativeFee, uint lzTokenFee) _fee, address _refundAddress) external payable"
];

const Bridge = ({ wallet, currentChain }) => {
  const [amount, setAmount] = useState('');
  const [destinationChain, setDestinationChain] = useState('Base Sepolia');
  const [error, setError] = useState('');

  const handleBridge = async () => {
    if (!wallet || !currentChain) {
      setError('Please connect your wallet and ensure you are on a supported network.');
      return;
    }

    setError('');

    try {
      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      const contractAddress = stablecoinContractAddresses[currentChain.name];
      const contract = new ethers.Contract(contractAddress, stablecoinABI, signer);

      const inputAmount = ethers.parseUnits(amount, 18);
      const selectedChain = chainOptions.find(chain => chain.name === destinationChain);
      if (!selectedChain) {
        throw new Error('Invalid destination chain selected');
      }

      const paddedAddress = ethers.zeroPadValue(wallet.address, 32);

      const sendParam = {
        dstEid: selectedChain.dstEid,
        to: paddedAddress,
        amountLD: inputAmount,
        minAmountLD: inputAmount,
        extraOptions: "0x",
        composeMsg: "0x",
        oftCmd: "0x"
      };

      const messagingFee = {
        nativeFee: ethers.parseEther("0.01"), // 10000000000000000 wei
        lzTokenFee: 0
      };

      // Estimate gas
      const gasEstimate = await contract.send.estimateGas(
        sendParam,
        messagingFee,
        wallet.address,
        {
          value: messagingFee.nativeFee
        }
      );

      console.log('Estimated gas:', gasEstimate.toString());

      // Add 20% to the gas estimate
    //   const gasLimit = gasEstimate.mul(120).div(100);

      const tx = await contract.send(
        sendParam,
        messagingFee,
        wallet.address,
        {
          value: messagingFee.nativeFee,
        //   gasLimit: gasLimit
        }
      );

      console.log('Bridge transaction sent:', tx.hash);
      await tx.wait();
      console.log('Bridge transaction confirmed');
      alert('Tokens bridged successfully!');
    } catch (error) {
      console.error('Error bridging tokens:', error);
      setError(`Failed to bridge tokens: ${error.message}`);
    }
  };

  return (
    <div className="bridge-container">
      <h2>Bridge Tokens</h2>
      <div className="bridge-form">
        <input
          type="text"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          placeholder="Amount to bridge"
        />
        <select
          value={destinationChain}
          onChange={(e) => setDestinationChain(e.target.value)}
        >
          {chainOptions.map(chain => (
            <option key={chain.name} value={chain.name}>{chain.name}</option>
          ))}
        </select>
        <button onClick={handleBridge}>Bridge</button>
      </div>
      {error && <p className="error-message">{error}</p>}
    </div>
  );
};

export default Bridge;