import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import './Mint.css';
import TransactionStatus from './TransactionStatus';

const contractAddress = '0x47861D458fe99BfC304FCeF3eD95bb6b29E2AB93';
const stablecoinContractAddress = '0x178FFc3586c8FBE0Dc2788E29730D16bB3b7E7Bf'; // Replace with the actual stablecoin contract address
const nftContractAddress = '0x7d8002b91f00E7e795e58815FDD096Eff85b8034';

const contractABI = [
  "function supply(address _nftAddress, uint256 _tokenId) public",
  "function withdraw(address _nftAddress, uint256 _tokenId) public",
  "function sendToMinter(uint32 _dstEid, uint256 _amount, address _recipient, uint8 _choice, bytes calldata _options) public payable",
  "function userAddressToNftCollectionSuppliedCount(address, address) public view returns (uint256)",
  "function userAddressToNftCollectionTokenIds(address, address, uint256) public view returns (uint256)",
  "function getMaxMintableByUser(address) public view returns (uint256)",
  "function repay(uint32 _dstEid, uint256 _amount, address _recipient, uint8 _choice, bytes calldata _options) public payable"
];
const nftABI = [
  "function approve(address to, uint256 tokenId) public",
  "function balanceOf(address owner) public view returns (uint256)",
  "function tokenOfOwnerByIndex(address owner, uint256 index) public view returns (uint256)",
  "function tokenURI(uint256 tokenId) public view returns (string memory)"
];
const stablecoinABI = [
  "function balanceOf(address owner) public view returns (uint256)",
  "function approve(address spender, uint256 amount) public returns (bool)"
];

const chainOptions = [
  { name: 'Base', dstEid: 40245, selection: 0 },
  { name: 'Optimism', dstEid: 40232, selection: 1 },
  { name: 'Arbitrum', dstEid: 40231, selection: 2 },
  { name: 'Linea', dstEid: 40287, selection: 3 }
];

const Mint = ({ wallet, currentChain, baseSepolia }) => {
  const [nftAddress, setNftAddress] = useState(nftContractAddress);
  const [tokenId, setTokenId] = useState('');
  const [destinationChain, setDestinationChain] = useState('Base');
  const [numberToMint, setNumberToMint] = useState('');
  const [contract, setContract] = useState(null);
  const [nftContract, setNftContract] = useState(null);
  const [userNftBalance, setUserNftBalance] = useState(0);
  const [selectedTokenId, setSelectedTokenId] = useState(null);
  const [nftBalance, setNftBalance] = useState(0);
  const [userNFTs, setUserNFTs] = useState([]);
  const [suppliedNFTs, setSuppliedNFTs] = useState([]);
  const [lastTxHash, setLastTxHash] = useState('');
  const [selectedSuppliedTokenId, setSelectedSuppliedTokenId] = useState(null);
  const [maxMintable, setMaxMintable] = useState('0');
  const [isMaxMintableHighlighted, setIsMaxMintableHighlighted] = useState(false);
  const [amountToRepay, setAmountToRepay] = useState('');
  const [stablecoinBalance, setStablecoinBalance] = useState('0');
  const [stablecoinContract, setStablecoinContract] = useState(null);
  const [baseSepoliaProvider, setBaseSepoliaProvider] = useState(null);
  const [baseSepoliaContract, setBaseSepoliaContract] = useState(null);
  const [baseSepoliaNftContract, setBaseSepoliaNftContract] = useState(null);
  const [currentChainContract, setCurrentChainContract] = useState(null);

  const message = "Minting stablecoins";
  const options = '0x000301001101000000000000000000000000000aae60010013030000000000000000000000000000000aae60';

  const isBaseSepolia = currentChain && currentChain.name === 'Base Sepolia';

  const isWalletConnected = wallet && wallet.address;

  useEffect(() => {
    if (baseSepolia) {
      const provider = new ethers.JsonRpcProvider(baseSepolia.rpcUrl);
      setBaseSepoliaProvider(provider);
      
      const newContract = new ethers.Contract(contractAddress, contractABI, provider);
      setBaseSepoliaContract(newContract);
      
      const newNftContract = new ethers.Contract(nftContractAddress, nftABI, provider);
      setBaseSepoliaNftContract(newNftContract);
    }
  }, [baseSepolia]);

  useEffect(() => {
    if (isWalletConnected && baseSepoliaContract && baseSepoliaNftContract) {
      fetchUserNFTs();
      fetchSuppliedNFTs();
      fetchMaxMintable();
    }
  }, [isWalletConnected, baseSepoliaContract, baseSepoliaNftContract]);

  useEffect(() => {
    if (isWalletConnected && currentChain) {
      fetchStablecoinBalance();
    }
  }, [wallet, currentChain]);

  useEffect(() => {
    if (wallet && currentChain) {
      const provider = new ethers.JsonRpcProvider(currentChain.rpcUrl);
      const newContract = new ethers.Contract(contractAddress, contractABI, provider);
      setCurrentChainContract(newContract);
    }
  }, [wallet, currentChain]);

  const fetchUserNFTs = async () => {
    if (!baseSepoliaNftContract || !wallet) return;
    try {
      const balance = await baseSepoliaNftContract.balanceOf(wallet.address);
      const tokenIds = [];
      for (let i = 0; i < balance; i++) {
        const tokenId = await baseSepoliaNftContract.tokenOfOwnerByIndex(wallet.address, i);
        tokenIds.push(tokenId.toString());
      }
      setUserNFTs(tokenIds);
      setUserNftBalance(balance.toString());
    } catch (error) {
      console.error("Error fetching user NFTs:", error);
    }
  };

  const fetchSuppliedNFTs = async () => {
    if (!baseSepoliaContract || !wallet) return;
    try {
      const suppliedCount = await baseSepoliaContract.userAddressToNftCollectionSuppliedCount(wallet.address, nftContractAddress);
      const suppliedTokenIds = [];
      for (let i = 0; i < suppliedCount; i++) {
        const tokenId = await baseSepoliaContract.userAddressToNftCollectionTokenIds(wallet.address, nftContractAddress, i);
        suppliedTokenIds.push(tokenId.toString());
      }
      setSuppliedNFTs(suppliedTokenIds);
    } catch (error) {
      console.error("Error fetching supplied NFTs:", error);
    }
  };

  const fetchMaxMintable = async () => {
    if (!baseSepoliaContract || !wallet) return;
    try {
      const maxMintableAmount = await baseSepoliaContract.getMaxMintableByUser(wallet.address);
      setMaxMintable(ethers.formatUnits(maxMintableAmount, 18));
    } catch (error) {
      console.error("Error fetching max mintable amount:", error);
    }
  };

  const fetchStablecoinBalance = async () => {
    if (!wallet || !currentChain) return;

    try {
      const provider = new ethers.JsonRpcProvider(currentChain.rpcUrl);
      const stablecoinContract = new ethers.Contract(stablecoinContractAddress, stablecoinABI, provider);
      const balance = await stablecoinContract.balanceOf(wallet.address);
      setStablecoinBalance(ethers.formatUnits(balance, 18));
    } catch (error) {
      console.error("Error fetching stablecoin balance:", error);
      setStablecoinBalance('0');
    }
  };

  const handleApproveNFT = async () => {
    if (!isWalletConnected) {
      alert('Please connect your wallet first.');
      return;
    }

    try {
      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      const nftContractWithSigner = baseSepoliaNftContract.connect(signer);
      const tx = await nftContractWithSigner.approve(contractAddress, tokenId);
      await tx.wait();
      // alert('NFT approved successfully!');
    } catch (error) {
      console.error('Error approving NFT:', error);
      alert(`Failed to approve NFT: ${error.message}`);
    }
  };

  const handleSupply = async () => {
    if (!isWalletConnected) {
      alert('Please connect your wallet first.');
      return;
    }

    try {
      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      const contractWithSigner = baseSepoliaContract.connect(signer);
      const tx = await contractWithSigner.supply(nftAddress, tokenId);
      await tx.wait();
      // alert('NFT supplied successfully!');
      fetchUserNFTs();
      fetchSuppliedNFTs();
    } catch (error) {
      console.error('Error supplying NFT:', error);
      alert(`Failed to supply NFT: ${error.message}`);
    }
  };

  const handleWithdraw = async (tokenIdToWithdraw) => {
    if (!isWalletConnected) {
      alert('Please connect your wallet first.');
      return;
    }

    try {
      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      const contractWithSigner = baseSepoliaContract.connect(signer);
      const tx = await contractWithSigner.withdraw(nftAddress, tokenIdToWithdraw);
      await tx.wait();
      // alert('NFT withdrawn successfully!');
      fetchUserNFTs();
      fetchSuppliedNFTs();
    } catch (error) {
      console.error('Error withdrawing NFT:', error);
      alert(`Failed to withdraw NFT: ${error.message}`);
    }
  };

  const handleMint = async () => {
    if (!isWalletConnected || !currentChainContract) {
      alert('Please connect your wallet and ensure you are on a supported network.');
      return;
    }

    const inputAmount = ethers.parseUnits(numberToMint || '0', 18);
    const maxMintableAmount = ethers.parseUnits(maxMintable, 18);

    if (inputAmount > maxMintableAmount) {
      setIsMaxMintableHighlighted(true);
      setTimeout(() => setIsMaxMintableHighlighted(false), 2000);
      return;
    }

    try {
      const selectedChain = chainOptions.find(chain => chain.name === destinationChain);
      if (!selectedChain) {
        throw new Error('Invalid destination chain selected');
      }

      const { dstEid } = selectedChain;
      const recipient = wallet.address;
      const options = '0x000301001101000000000000000000000000000aae60010013030000000000000000000000000000000aae60';
      const choice = 1;

      console.log('Minting parameters:', {
        dstEid,
        amount: inputAmount.toString(),
        recipient,
        choice,
        options
      });

      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      const contractWithSigner = currentChainContract.connect(signer);

      const tx = await contractWithSigner.sendToMinter(
        dstEid,
        inputAmount,
        recipient,
        choice,
        options,
        {
          value: ethers.parseEther('0.001'),
        }
      );

      console.log('Transaction sent:', tx.hash);
      setLastTxHash(tx.hash);
      const receipt = await tx.wait();
      console.log('Transaction confirmed in block:', receipt.blockNumber);
      console.log('Gas used:', receipt.gasUsed.toString());

      // alert('Stablecoins minted successfully!');
    } catch (error) {
      console.error('Error minting stablecoins:', error);
      alert(`Failed to mint stablecoins: ${error.message}`);
    }
  };

  const handleApproveRepayment = async () => {
    if (!isWalletConnected) {
      alert('Please connect your wallet.');
      return;
    }

    if (!currentChain) {
      alert('Please ensure you are on a supported network.');
      return;
    }

    const repayAmount = ethers.parseUnits(amountToRepay || '0', 18);

    try {
      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      const stablecoinContractWithSigner = new ethers.Contract(stablecoinContractAddress, stablecoinABI, signer);

      const tx = await stablecoinContractWithSigner.approve(contractAddress, repayAmount);
      console.log('Approval transaction sent:', tx.hash);
      setLastTxHash(tx.hash);
      await tx.wait();
      // console.log('Approval transaction confirmed');

      // alert('Repayment amount approved successfully!');
    } catch (error) {
      console.error('Error approving repayment:', error);
      alert(`Failed to approve repayment: ${error.message}`);
    }
  };

  const handleRepayDebt = async () => {
    if (!isWalletConnected) {
      alert('Please connect your wallet.');
      return;
    }

    if (!currentChain) {
      alert('Please ensure you are on a supported network.');
      return;
    }

    const repayAmount = ethers.parseUnits(amountToRepay || '0', 18);

    try {
      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      const contractWithSigner = new ethers.Contract(contractAddress, contractABI, signer);

      const tx = await contractWithSigner.repay(
        40245, // _dstEid
        repayAmount,
        wallet.address, // _recipient
        4, // _choice
        options,
        {
          value: ethers.parseEther('0.001'),
        }
      );

      console.log('Repay transaction sent:', tx.hash);
      setLastTxHash(tx.hash);
      const receipt = await tx.wait();
      console.log('Repay transaction confirmed in block:', receipt.blockNumber);

      // alert('Debt repaid successfully!');
      fetchStablecoinBalance();
      setAmountToRepay('');
    } catch (error) {
      console.error('Error repaying debt:', error);
      alert(`Failed to repay debt: ${error.message}`);
    }
  };

  const handleNftSelection = (tokenId) => {
    setSelectedTokenId(tokenId);
    setTokenId(tokenId);
  };

  const handleSuppliedNftSelection = (tokenId) => {
    setSelectedSuppliedTokenId(tokenId === selectedSuppliedTokenId ? null : tokenId);
  };

  return (
    <div className="mint-container">
      <div className="left-column">
        <div className="card manage-collateral">
          <h2>Manage Collateral</h2>
          <div className="nft-collection-info">
            <img src="/images/pudgies.png" alt="Pudgy Penguins" className="collection-image" />
            <h3>Pudgy Penguins</h3>
          </div>
          <h3>Available Collateral</h3>
          <div className="user-nfts">
            {userNFTs.length > 0 ? (
              userNFTs.map((tokenId, index) => (
                <div 
                  key={tokenId} 
                  className={`nft-placeholder ${selectedTokenId === tokenId ? 'selected' : ''}`}
                  onClick={() => handleNftSelection(tokenId)}
                >
                  <img 
                    src={`/images/pudgie${index % 10}.png`} 
                    alt={`Pudgy Penguin #${tokenId}`} 
                    className="nft-image" 
                  />
                  <p>NFT #{tokenId}</p>
                </div>
              ))
            ) : (
              <p>No NFTs found in your wallet.</p>
            )}
          </div>
          {selectedTokenId !== null && (
            <div className="nft-actions">
              <button onClick={handleApproveNFT}>Approve NFT</button>
              <button onClick={handleSupply}>Supply NFT</button>
            </div>
          )}
          
          <h3>Supplied Collateral</h3>
          <div className="user-nfts">
            {suppliedNFTs.length > 0 ? (
              suppliedNFTs.map((tokenId, index) => (
                <div 
                  key={tokenId} 
                  className={`nft-placeholder ${selectedSuppliedTokenId === tokenId ? 'selected' : ''}`}
                  onClick={() => handleSuppliedNftSelection(tokenId)}
                >
                  <img 
                    src={`/images/pudgie${(index + 1) % 10 || 10}.png`} 
                    alt={`Pudgy Penguin #${tokenId}`} 
                    className="nft-image" 
                  />
                  <p>NFT #{tokenId}</p>
                </div>
              ))
            ) : (
              <p>No NFTs supplied to the contract.</p>
            )}
          </div>
          {selectedSuppliedTokenId !== null && (
            <div className="nft-actions">
              <button onClick={() => handleWithdraw(selectedSuppliedTokenId)}>Withdraw NFT</button>
            </div>
          )}
        </div>
      </div>
      
      <div className="right-column">
        {isBaseSepolia && (
          <div className="card mint-stablecoins">
            <h2>Mint Stablecoins</h2>
            <p className={isMaxMintableHighlighted ? 'highlighted-max-mintable' : ''}>
              Max Amount Available to Mint: {maxMintable}
            </p>
            <div className="mint-inputs">
              <div className="input-group">
                <label htmlFor="destinationChain">Destination Chain:</label>
                <select
                  id="destinationChain"
                  value={destinationChain}
                  onChange={(e) => setDestinationChain(e.target.value)}
                >
                  {chainOptions.map(chain => (
                    <option key={chain.name} value={chain.name}>{chain.name}</option>
                  ))}
                </select>
              </div>
              <div className="input-group">
                <label htmlFor="numberToMint">Amount to Mint:</label>
                <input
                  type="text"
                  id="numberToMint"
                  value={numberToMint}
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^0-9]/g, '');
                    setNumberToMint(value);
                  }}
                  placeholder="Amount of stablecoins to mint"
                  className="no-spinner"
                />
              </div>
            </div>
            <button onClick={handleMint} className="mint-button">Mint Stablecoins</button>
            
            {lastTxHash && <TransactionStatus txHash={lastTxHash} />}
          </div>
        )}
        
        <div className="card repay-debt">
          <h2>Repay Debt</h2>
          <p>
            Max Amount Repayable on {currentChain ? currentChain.name : 'current chain'}: {stablecoinBalance}
          </p>
          <div className="mint-inputs">
            <div className="input-group">
              <label htmlFor="amountToRepay">Amount to Repay:</label>
              <input
                type="text"
                id="amountToRepay"
                value={amountToRepay}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9.]/g, '');
                  setAmountToRepay(value);
                }}
                placeholder="Amount of stablecoins to repay"
                className="no-spinner"
              />
            </div>
          </div>
          <div className="repay-actions">
            <button onClick={handleApproveRepayment} className="approve-button">Approve Repayment</button>
            <button onClick={handleRepayDebt} className="repay-button">Repay Debt</button>
          </div>
          
          {lastTxHash && <TransactionStatus txHash={lastTxHash} />}
        </div>
      </div>
    </div>
  );
};

export default Mint;