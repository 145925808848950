import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import Header from './components/Header';
import Dashboard from './components/Dashboard';
import Strategies from './components/Strategies';
import Mint from './components/Mint';
import Get from './components/Get';
import Bridge from './components/Bridge'; // Add this line
import './App.css';
// import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';

const chains = [
  { name: 'Base Sepolia', chainId: '0x14a34', rpcUrl: "https://sepolia.base.org", networkId: 84532 },
  { name: 'Optimism Sepolia', chainId: '0xaa37dc', rpcUrl: "https://sepolia.optimism.io", networkId: 11155420 },
  { name: 'Arbitrum Sepolia', chainId: '0x66eee', rpcUrl: "https://sepolia-rollup.arbitrum.io/rpc", networkId: 421614 },
  { name: 'Linea Sepolia', chainId: '0xe705', rpcUrl: "https://rpc.sepolia.linea.build", networkId: 59141 }
];

const baseSepolia = chains.find(chain => chain.name === 'Base Sepolia');

function App() {
  const [currentPage, setCurrentPage] = useState('dashboard');
  const [wallet, setWallet] = useState(null);
  const [currentChain, setCurrentChain] = useState(null);

  const connectWallet = async () => {
    if (typeof window.ethereum !== 'undefined') {
      try {
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner();
        const address = await signer.getAddress();
        setWallet({ provider, signer, address });
        await detectCurrentChain();
      } catch (error) {
        console.error('Failed to connect wallet:', error);
        alert('Failed to connect wallet. Please try again.');
      }
    } else {
      console.error('MetaMask is not installed');
      alert('Please install MetaMask to use this feature');
    }
  };

  const detectCurrentChain = async () => {
    if (typeof window.ethereum !== 'undefined') {
      try {
        const chainId = await window.ethereum.request({ method: 'eth_chainId' });
        const networkId = await window.ethereum.request({ method: 'net_version' });
        const detectedChain = chains.find(chain => 
          chain.chainId.toLowerCase() === chainId.toLowerCase() && 
          chain.networkId.toString() === networkId
        );
        if (detectedChain) {
          setCurrentChain(detectedChain);
        } else {
          console.warn('Current chain not in supported list');
          setCurrentChain(null);
        }
      } catch (error) {
        console.error('Failed to detect current chain:', error);
      }
    }
  };

  const switchChain = async (chain) => {
    if (typeof window.ethereum !== 'undefined') {
      try {
        console.log(`Attempting to switch to chain: ${chain.name} (${chain.chainId})`);
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: chain.chainId }],
        });
        // After switching, check if we're on the correct network
        const networkId = await window.ethereum.request({ method: 'net_version' });
        console.log(`Switched to network with ID: ${networkId}`);
        if (networkId !== chain.networkId.toString()) {
          throw new Error('Switched to the wrong network');
        }
        setCurrentChain(chain);
      } catch (switchError) {
        console.error('Switch error:', switchError);
        // This error code indicates that the chain has not been added to MetaMask.
        if (switchError.code === 4902 || switchError.message === 'Switched to the wrong network') {
          try {
            console.log(`Attempting to add chain: ${chain.name}`);
            await window.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [{
                chainId: chain.chainId,
                chainName: chain.name,
                nativeCurrency: {
                  name: "ETH",
                  symbol: "ETH",
                  decimals: 18
                },
                rpcUrls: [chain.rpcUrl],
                blockExplorerUrls: [getBlockExplorerUrl(chain.name)],
              }],
            });
            // After adding the chain, we need to switch to it
            await window.ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: chain.chainId }],
            });
            // Verify the network after switching
            const newNetworkId = await window.ethereum.request({ method: 'net_version' });
            console.log(`Switched to network with ID: ${newNetworkId}`);
            if (newNetworkId !== chain.networkId.toString()) {
              throw new Error('Failed to switch to the correct network');
            }
            setCurrentChain(chain);
          } catch (addError) {
            console.error('Add network error:', addError);
            alert(`Failed to switch to ${chain.name} network. Please try again or add it manually in your wallet.`);
          }
        } else {
          console.error('Failed to switch network', switchError);
          alert(`Failed to switch to ${chain.name} network. Please try again.`);
        }
      }
    }
  };

  const getBlockExplorerUrl = (chainName) => {
    switch (chainName) {
      case 'Base Sepolia':
        return 'https://sepolia.basescan.org';
      case 'Optimism Sepolia':
        return 'https://sepolia-optimism.etherscan.io';
      case 'Arbitrum Sepolia':
        return 'https://sepolia-explorer.arbitrum.io';
      case 'Linea Sepolia':
        return 'https://sepolia.lineascan.build';
      default:
        return '';
    }
  };

  useEffect(() => {
    if (typeof window.ethereum !== 'undefined') {
      window.ethereum.on('accountsChanged', (accounts) => {
        if (accounts.length === 0) {
          setWallet(null);
          setCurrentChain(null);
        } else {
          connectWallet();
        }
      });

      window.ethereum.on('chainChanged', () => {
        detectCurrentChain();
      });
    }

    detectCurrentChain();
  }, []);

  const renderContent = () => {
    switch (currentPage) {
      case 'dashboard':
        return <Dashboard wallet={wallet} currentChain={currentChain} baseSepolia={baseSepolia} />;
      case 'strategies':
        return <Strategies wallet={wallet} currentChain={currentChain} baseSepolia={baseSepolia} />;
      case 'mint':
        return <Mint wallet={wallet} currentChain={currentChain} baseSepolia={baseSepolia} />;
      case 'get':
        return <Get wallet={wallet} />;
      case 'bridge': // Add this case
        return <Bridge wallet={wallet} currentChain={currentChain} />;
      default:
        return <Dashboard wallet={wallet} currentChain={currentChain} baseSepolia={baseSepolia} />;
    }
  };

  return (
    <div className="App">
      <Header
        wallet={wallet}
        connectWallet={connectWallet}
        currentChain={currentChain}
        chains={chains}
        switchChain={switchChain}
        setCurrentPage={setCurrentPage}
      />
      {renderContent()}
    </div>
  );
}

export default App;