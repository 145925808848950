import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import './Strategies.css';
import TransactionStatus from './TransactionStatus';

const contractAddress = '0x2875c0971817eBF19d2E1e0e35bB6A928Ac958DA';
const stablecoinContractAddress = '0xEBDA333C1725CB91B2e0e35221F65297889966f5';

const contractABI = [
  "function sendToMinter(uint32 _dstEid, uint256 _amount, address _recipient, uint8 _choice, bytes calldata _options) public payable",
  "function getMaxMintableByUser(address) public view returns (uint256)"
];

const chainOptions = [
  { name: 'Base Sepolia', dstEid: 40245, selection: 0 },
  { name: 'Optimism Sepolia', dstEid: 40232, selection: 1 },
  { name: 'Arbitrum Sepolia', dstEid: 40231, selection: 2 },
  { name: 'Linea Sepolia', dstEid: 40287, selection: 3 }
];

const Strategies = ({ wallet, currentChain, baseSepolia }) => {
  const [numberToMint, setNumberToMint] = useState('');
  const [maxMintable, setMaxMintable] = useState('0');
  const [lastTxHash, setLastTxHash] = useState('');
  const [isMaxMintableHighlighted, setIsMaxMintableHighlighted] = useState(false);
  const [currentChainContract, setCurrentChainContract] = useState(null);

  const options = '0x000301001101000000000000000000000000000aae60010013030000000000000000000000000000000aae60';

  const isWalletConnected = wallet && wallet.address;

  useEffect(() => {
    console.log("Wallet:", wallet);
    console.log("Current Chain:", currentChain);
    console.log("Is Wallet Connected:", isWalletConnected);

    if (isWalletConnected && currentChain) {
      console.log("Initializing contract with RPC URL:", currentChain.rpcUrl);
      const provider = new ethers.JsonRpcProvider(currentChain.rpcUrl);
      const newContract = new ethers.Contract(contractAddress, contractABI, provider);
      setCurrentChainContract(newContract);
      fetchMaxMintable();
    }
  }, [isWalletConnected, currentChain]);

  const fetchMaxMintable = async () => {
    if (!currentChainContract || !isWalletConnected) {
      console.log("Cannot fetch max mintable: Contract or wallet not available");
      return;
    }
    try {
      const maxMintableAmount = await currentChainContract.getMaxMintableByUser(wallet.address);
      setMaxMintable(ethers.formatUnits(maxMintableAmount, 18));
      console.log("Max Mintable Amount:", ethers.formatUnits(maxMintableAmount, 18));
    } catch (error) {
      console.error("Error fetching max mintable amount:", error);
    }
  };

  const handleMintAndDeposit = async () => {
    console.log("Mint and Deposit clicked");
    console.log("Is Wallet Connected:", isWalletConnected);
    console.log("Current Chain:", currentChain);
    console.log("Current Chain Contract:", currentChainContract);

    if (!isWalletConnected) {
      alert('Please connect your wallet.');
      return;
    }

    if (!currentChain || !chainOptions.some(chain => chain.name === currentChain.name)) {
      alert('Please ensure you are on a supported network.');
      return;
    }

    if (!currentChainContract) {
      alert('Contract not initialized. Please try again.');
      return;
    }

    const inputAmount = ethers.parseUnits(numberToMint || '0', 18);

    try {
      const selectedChain = chainOptions.find(chain => chain.name === currentChain.name);
      if (!selectedChain) {
        throw new Error('Invalid destination chain selected');
      }

      const dstEid = 40232;
      const recipient = wallet.address;
      const choice = 8; // Set to 7 for Strategies page

      console.log('Minting parameters:', {
        dstEid,
        amount: inputAmount.toString(),
        recipient,
        choice,
        options
      });

      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      const contractWithSigner = currentChainContract.connect(signer);

      const tx = await contractWithSigner.sendToMinter(
        dstEid,
        inputAmount,
        recipient,
        choice,
        options,
        {
          value: ethers.parseEther('0.001'),
        }
      );

      console.log('Transaction sent:', tx.hash);
      setLastTxHash(tx.hash);
      const receipt = await tx.wait();
      console.log('Transaction confirmed in block:', receipt.blockNumber);
      console.log('Gas used:', receipt.gasUsed.toString());

      // alert('Stablecoins minted and deposited successfully!');
      setNumberToMint('');
    } catch (error) {
      console.error('Error minting and depositing stablecoins:', error);
      alert(`Failed to mint and deposit stablecoins: ${error.message}`);
    }
  };

  return (
    <div className="strategies-container">
      <h1>Strategies</h1>
      <div className="strategy-card">
        <h2>Mint & Deposit</h2>
        <h3>Strategy: AAVE Autocompounder Vault on Optimism</h3>
        {/* <p>Wallet Connected: {isWalletConnected ? 'Yes' : 'No'}</p>
        <p>Current Chain: {currentChain ? currentChain.name : 'Not selected'}</p> */}
        <div className="mint-deposit-inputs">
          <input
            type="text"
            value={numberToMint}
            onChange={(e) => {
              const value = e.target.value.replace(/[^0-9]/g, '');
              setNumberToMint(value);
            }}
            placeholder="Amount of stablecoins to mint"
            className="no-spinner"
          />
          <button onClick={handleMintAndDeposit} className="mint-deposit-button">Mint & Deposit</button>
        </div>
        {lastTxHash && <TransactionStatus txHash={lastTxHash} />}
      </div>
    </div>
  );
};

export default Strategies;