import React, { useState, useEffect } from 'react';
import { getMessagesBySrcTxHash } from '@layerzerolabs/scan-client';

const TransactionStatus = ({ txHash }) => {
  const [status, setStatus] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    let intervalId;

    const fetchStatus = async () => {
      if (!txHash) return;

      setLoading(true);
      setError(null);

      try {
        const { messages } = await getMessagesBySrcTxHash(40245, txHash); // 40245 is the chainId for Base Sepolia
        if (messages && messages.length > 0) {
          const newStatus = messages[0].status;
          setStatus(newStatus);
          
          // If status is DELIVERED, clear the interval
          if (newStatus === 'DELIVERED') {
            clearInterval(intervalId);
          }
        } else {
          setStatus('No messages found');
        }
      } catch (err) {
        console.error('Error fetching transaction status:', err);
        setError('Failed to fetch transaction status');
      } finally {
        setLoading(false);
      }
    };

    fetchStatus(); // Initial fetch

    // Set up interval to fetch status every 5 seconds
    intervalId = setInterval(fetchStatus, 5000);

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, [txHash]);

  const getStatusStyles = (status) => {
    switch (status) {
      case 'INFLIGHT':
        return { backgroundColor: '#8A2BE2', color: 'white' }; // Purple background, white text
      case 'DELIVERED':
        return { backgroundColor: '#4CAF50', color: 'white' }; // Green background, white text
      case 'FAILED':
        return { backgroundColor: '#FF0000', color: 'white' }; // Red background, white text
      case 'PAYLOAD_STORED':
        return { backgroundColor: '#FFFF00', color: '#333333' }; // Yellow background, dark grey text
      case 'BLOCKED':
        return { backgroundColor: '#444444', color: 'white' }; // Dark Grey background, white text
      case 'CONFIRMING':
        return { backgroundColor: '#CCCCCC', color: '#333333' }; // Light Grey background, dark grey text
      default:
        return { backgroundColor: '#FFFFFF', color: '#333333' }; // White background, dark grey text
    }
  };

  if (loading) return <p>Loading transaction status...</p>;
  if (error) return <p>Error: {error}</p>;
  if (!status) return null;

  const styles = getStatusStyles(status);

  return (
    <div 
      className="transaction-status"
      style={styles}
    >
      <h3>Transaction Status</h3>
      <p>Status: {status}</p>
    </div>
  );
};

export default TransactionStatus;