import React from 'react';
import './Header.css';

const Header = ({ setCurrentPage, wallet, connectWallet, currentChain, chains, switchChain }) => {
  const handleChainChange = (e) => {
    const selectedChain = chains.find(chain => chain.name === e.target.value);
    if (selectedChain && (!currentChain || selectedChain.chainId !== currentChain.chainId)) {
      switchChain(selectedChain);
    }
  };

  return (
    <header>
      <nav>
        <h1 className="logo">Membrane</h1>
        <ul>
          <li><a href="#" onClick={() => setCurrentPage('dashboard')}>Dashboard</a></li>
          {/* <li><a href="#" onClick={() => setCurrentPage('markets')}>Markets</a></li> */}
          <li><a href="#" onClick={() => setCurrentPage('strategies')}>Strategies</a></li>
          <li><a href="#" onClick={() => setCurrentPage('mint')}>Mint</a></li>
          <li><a href="#" onClick={() => setCurrentPage('get')}>Get</a></li>
          <li><a href="#" onClick={() => setCurrentPage('bridge')}>Bridge</a></li> {/* Add this line */}
        </ul>
        <div className="wallet-controls">
          {wallet ? (
            <select 
              value={currentChain ? currentChain.name : ''}
              onChange={handleChainChange}
              className="chain-selector"
            >
              {!currentChain && <option value="">Select Chain</option>}
              {chains.map((chain) => (
                <option key={chain.chainId} value={chain.name}>
                  {chain.name}
                </option>
              ))}
            </select>
          ) : (
            <button onClick={connectWallet} className="connect-wallet-btn">Connect Wallet</button>
          )}
        </div>
      </nav>
    </header>
  );
};

export default Header;