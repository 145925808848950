import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import './Dashboard.css';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const contractAddress = '0x47861D458fe99BfC304FCeF3eD95bb6b29E2AB93';
const stablecoinContractAddress = '0x178FFc3586c8FBE0Dc2788E29730D16bB3b7E7Bf';
const nftContractAddress = '0x7d8002b91f00E7e795e58815FDD096Eff85b8034';

const contractABI = [
  "function numberOfNftsUserHasSupplied(address user) public view returns (uint256)",
  "function userAddressToNumberOfStablecoinsMinted(address user) public view returns (uint256)",
  "function getBorrowerHealthFactor(address) public view returns (uint256)"
];
const nftABI = [
  "function balanceOf(address owner) public view returns (uint256)"
];
const stablecoinABI = [
  "function balanceOf(address account) public view returns (uint256)"
];

const networks = [
  { name: 'Optimism Sepolia', rpcUrl: 'https://sepolia.optimism.io' },
  { name: 'Base Sepolia', rpcUrl: 'https://sepolia.base.org' },
  { name: 'Arbitrum Sepolia', rpcUrl: 'https://arb-sepolia.g.alchemy.com/v2/LHwHYShuz3y8Y0JH0k2tx1IZy7GubR8O' },
  { name: 'Linea Sepolia', rpcUrl: 'https://linea-sepolia.g.alchemy.com/v2/LHwHYShuz3y8Y0JH0k2tx1IZy7GubR8O' }
];

const Dashboard = ({ wallet, currentChain, baseSepolia }) => {
  const [healthFactor, setHealthFactor] = useState(0);
  const [suppliedNFTs, setSuppliedNFTs] = useState([]);
  const [availableNFTs, setAvailableNFTs] = useState([]);
  const [stablecoinBalances, setStablecoinBalances] = useState({});

  useEffect(() => {
    if (wallet && baseSepolia) {
      fetchBaseSepoliaData();
    }
  }, [wallet, baseSepolia]);

  useEffect(() => {
    if (wallet) {
      fetchAllChainsData();
    }
  }, [wallet]);

  const fetchBaseSepoliaData = async () => {
    try {
      const provider = new ethers.JsonRpcProvider(baseSepolia.rpcUrl);
      const contract = new ethers.Contract(contractAddress, contractABI, provider);
      const nftContract = new ethers.Contract(nftContractAddress, nftABI, provider);

      // Fetch health factor
      const hf = await contract.getBorrowerHealthFactor(wallet.address);
      setHealthFactor(ethers.formatUnits(hf, 18));

      // Fetch supplied NFTs
      const suppliedCount = await contract.numberOfNftsUserHasSupplied(wallet.address);
      setSuppliedNFTs(new Array(Number(suppliedCount)).fill(0));

      // Fetch available NFTs
      const balance = await nftContract.balanceOf(wallet.address);
      setAvailableNFTs(new Array(Number(balance)).fill(0));

    } catch (error) {
      console.error("Error fetching Base Sepolia data:", error);
    }
  };

  const fetchAllChainsData = async () => {
    const balances = {};
    for (const network of networks) {
      try {
        const provider = new ethers.JsonRpcProvider(network.rpcUrl);
        const stablecoinContract = new ethers.Contract(stablecoinContractAddress, stablecoinABI, provider);

        // Fetch minted stablecoins
        const balance = await stablecoinContract.balanceOf(wallet.address);
        balances[network.name] = ethers.formatUnits(balance, 18);
      } catch (error) {
        console.error(`Error fetching data for ${network.name}:`, error);
        balances[network.name] = '0';
      }
    }
    setStablecoinBalances(balances);
  };

  // Dummy data for NFT collection price chart
  const chartData = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
    datasets: [
      {
        label: 'NFT Collection Price',
        data: [10, 15, 13, 20, 18, 25, 30],
        fill: false,
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1
      }
    ]
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'NFT Collection Price Trend'
      }
    }
  };

  return (
    <div className="dashboard dashboard-container">
      <div className="dashboard-content">
        <div className="content-card wide">
          <h1>Dashboard</h1>
          <h2>Open Positions</h2>
          <table className="data-table">
            <thead>
              <tr>
                <th>Chain</th>
                <th>Stablecoins Minted</th>
              </tr>
            </thead>
            <tbody>
              {networks.map((network) => (
                <tr key={network.name}>
                  <td>{network.name}</td>
                  <td>{parseFloat(stablecoinBalances[network.name] || 0).toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="dashboard-stats">
        <div className="stat-card">
          <div className="stat-header">
            <h3>Your NFTs Supplied</h3>
            <span className="icon">🖼️</span>
          </div>
          <div className="stat-content">
            <p className="stat-value">{suppliedNFTs.length}</p>
          </div>
        </div>
        <div className="stat-card">
          <div className="stat-header">
            <h3>Your NFTs Available</h3>
            <span className="icon">🎨</span>
          </div>
          <div className="stat-content">
            <p className="stat-value">{availableNFTs.length}</p>
          </div>
        </div>
        <div className="stat-card">
          <div className="stat-header">
            <h3>Total MUSD Minted</h3>
            <span className="icon">💰</span>
          </div>
          <div className="stat-content">
            <p className="stat-value">
              {Object.values(stablecoinBalances).reduce((a, b) => a + parseFloat(b), 0).toFixed(2)}
            </p>
          </div>
        </div>
        <div className="stat-card">
          <div className="stat-header">
            <h3>Health Factor</h3>
            <span className="icon">❤️</span>
          </div>
          <div className="stat-content">
            <p className="stat-value">{parseFloat(healthFactor).toFixed(2)}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;