import React from 'react';
import { ethers } from 'ethers';

const nftContractAddress = '0x7d8002b91f00E7e795e58815FDD096Eff85b8034';

const Get = () => {
  const handleMint = async () => {
    if (window.ethereum) {
      try {
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner();
        const contract = new ethers.Contract(nftContractAddress, [
          'function mint() public'
        ], signer);

        const tx = await contract.mint();
        await tx.wait();
        // alert('Minting successful!');
      } catch (error) {
        console.error('Minting failed:', error);
        alert('Minting failed. Please try again.');
      }
    } else {
      alert('Please install MetaMask!');
    }
  };

  return (
    <div className="card content-card" style={{maxWidth: '800px', margin: '0 auto', padding: '20px', marginTop: '40px'}}>
      <h2>Mint Collateral NFTs</h2>
      <p>Use this button to mint a Pudgy Penguin to use as collateral on the Base Sepolia test network</p>
      <button className="mint-btn" onClick={handleMint}>Mint</button>
    </div>
  );
};

export default Get;